import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js";
import { css } from "@emotion/core";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';

const CountryTemplate = ({ data }) => (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={data.wordpressPage.excerpt}
      />
      <Helmet defer={false}>
        <body className="brands-all" />
        {data.wordpressPage.acf.ab_bkg_image != null &&
          <style type="text/css">{`
            body {
                background-image: url(`+data.wordpressPage.acf.ab_bkg_image.localFile.publicURL+`);
                background-size: cover;
                background-position: center center;
            }
          `}</style>
          }
      </Helmet>
      <header className="mainHead flexContainer portfolioHead faCenter fjCenter" css={css`
        background: ${data.wordpressPage.acf.brand_color};
        `}>      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/${data.wordpressPage.parent_element.slug}/`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: data.wordpressPage.parent_element.title+' Brands'}}/>
          </Link>

      {/* Nav Side */}
      <Navlinks />
      </div>
      </header>
      <section id="category-content" className="flexContainer secondaryTab">
        <div className="fCol12 maxTablet">
          <div className="flexContainer categoryInner headerInner">
            <h1 className="noIcon">All Brands</h1>    
          </div>
          <div className="flexContainer categoryInner">                 
            <div className="csContent fCol12">
              <ul>
                {data.allWordpressPage.edges.filter(el => el.node.acf.master_tether_selector != 'tether').map(brand => (
                  <React.Fragment>
                    <li className={brand.node.slug}>
                      <a className="fCol12 flexContainer" href={brand.node.path}>
                        <span className="brandName" dangerouslySetInnerHTML={{ __html: brand.node.acf.brand_name}}/>

                      </a>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>

            <h3 className="csHeader fCol12">COMING SOON:</h3> 
            <div className="csContent fCol12 comingSOON" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.category_coming_soon}}/>
          </div>
      </div>
    </section>

    </Layout>
  )
  
  export default CountryTemplate
  
  export const query = graphql`
    query($id: Int!) {
      allWordpressPage(filter: {acf: {type_of_page: {eq: "brand"}}}, sort: {fields: acf___brand_name}) {
        edges {
          node {
            id
            wordpress_id
            title
            path
            slug
            acf {
              brand_name
              brand_logo {
                localFile {
                  childImageSharp {
                    sizes(maxWidth:400) {
                      src
                    }
                  }
                }
              }
              master_tether_selector
            }
          }
        }
      }
      wordpressPage(wordpress_id: { eq: $id }) {
        title
        excerpt
        content
        parent_element {
          title
          slug
        }
        slug
        acf {
          ab_name
          ab_bkg_image {
            localFile {
              publicURL
            }
          }
          category_coming_soon_header
          category_coming_soon
        }
      }
      wp {
        pages(where: {id: $id}) {
          edges {
            node {
              childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
                edges {
                  node {
                    title
                    childPages {
                      edges {
                        node {
                          title
                          pageId
                        }
                      }
                    }
                    category_page_fields {
                      categoryIcon
                      categoryName
                    }
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
`